import { Collapse, Tooltip } from "antd";
import GoogleMaps from "google-map-react";
import { useEffect, useState } from "react";

const Navigation: React.FC = () => {
  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();
  const [prevCoords, setPrevCoords] = useState({ lat: 45.4231, lng: -75.6831 });

  // "Publishing" fake GPS coordinates
  useEffect(() => {
    if (map && maps) {
      let id = setTimeout(() => {
        let newCoords = {
          lat: prevCoords.lat + (Math.random() - 0.5) * 0.001,
          lng: prevCoords.lng + (Math.random() - 0.5) * 0.001,
        };
        map.data.add({
          geometry: new maps.Data.LineString([prevCoords, newCoords]),
        });

        map.setCenter(newCoords);
        setPrevCoords(newCoords);
      }, 3000);

      return () => clearTimeout(id);
    }
  }, [map, maps, prevCoords]);

  return (
    <Collapse defaultActiveKey={["1"]}>
      <Collapse.Panel header={<b>Map</b>} key="1" extra={<Tooltip title="Current location">{prevCoords.lat}, {prevCoords.lng}</Tooltip>}>
        <div style={{ height: 500 }}>
          <GoogleMaps
            bootstrapURLKeys={{
              key: "AIzaSyCewH8Ac2iyatqG-LvofENQHSsdeP5WBKg",
            }}
            defaultCenter={{ lat: 45.4231, lng: -75.6831 }}
            defaultZoom={17}
            onGoogleApiLoaded={(google) => {
              setMap(google.map);
              setMaps(google.maps);
            }}
          />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default Navigation;
